import React from 'react';

function rsvpVisuals(props) {
    return (
        <div>
            RSVP
        </div>
    );
}

export default rsvpVisuals;